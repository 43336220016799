import {createBrowserRouter, Outlet} from "react-router-dom";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import MainLayout from "../../components/MainLayout";
import CenterLayout from "../../components/CenterLayout";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <MainLayout><Home /></MainLayout>,
      },
      {
        path: "/login",
        element: <CenterLayout><Login /></CenterLayout>,
      },
    ],
  },
]);