import GlobalState from "./lib/contexts/GlobalContext";
import {RouterProvider} from "react-router-dom";
import {routes} from "./lib/router/routes";

function App() {
  return (
    <GlobalState>
      <RouterProvider router={routes} />
    </GlobalState>
  );
}

export default App;
