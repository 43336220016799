import React, {createContext, useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {isEmpty} from "lodash";

// List of initial state for all global data
export const initialState = {
  userInfo: {},
  externalApiJitsi: {}
};

// Actions
const SET_USER_INFO = "SET_USER_INFO";
const SET_EXTERNAL_API_JITSI_INFO = "SET_EXTERNAL_API_JITSI_INFO";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Shared Reducer For Global Context
const Reducer = (globalState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      if (JSON.stringify(action.payload) === JSON.stringify(globalState.userInfo)) {
        return globalState;
      }

      // clear hashmap when payload is an empty object
      if (isEmpty(action.payload)) {
        return {
          ...globalState,
          userInfo: {},
        };
      }

      return {
        ...globalState,
        userInfo: {
          ...globalState.userInfo,
          ...action.payload,
        },
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState
      }
    case SET_EXTERNAL_API_JITSI_INFO:
      return {
        ...globalState,
        externalApiJitsi: action.payload
      }
    default:
      return globalState;
  }
};

// Global State Which its provide context for children
const GlobalState = ({children, defaultInitialState = {}}) => {
  const [globalState, dispatch] = useReducer(Reducer, {...initialState, ...defaultInitialState});

  const setUserInfo = useCallback((value) => {
    dispatch({type: SET_USER_INFO, payload: value});
  }, []);

  const setExternalJitsiApi = useCallback((value) => {
    dispatch({type: SET_EXTERNAL_API_JITSI_INFO, payload: value});
  }, []);

  // Logout user
  const logoutCurrentUser = useCallback(() => {
    dispatch({type: LOGOUT_SUCCESS});
  }, []);

  const isLoggedIn = useMemo(() => {
    return !isEmpty(globalState.userInfo);
  }, [globalState.userInfo]);

  const contextValue = useMemo(() => {
    return {
      globalState,
      setUserInfo,
      logoutCurrentUser,
      isLoggedIn,
      setExternalJitsiApi
    };
  }, [globalState]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

// Create Global Context
export const GlobalContext = createContext(initialState);

// Export Global State Context Component
export default GlobalState;
